import { render, staticRenderFns } from "./ReportedContent.vue?vue&type=template&id=496f3dce&scoped=true&"
import script from "./ReportedContent.vue?vue&type=script&lang=js&"
export * from "./ReportedContent.vue?vue&type=script&lang=js&"
import style0 from "./ReportedContent.vue?vue&type=style&index=0&id=496f3dce&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496f3dce",
  null
  
)

export default component.exports