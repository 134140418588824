import { HttpError } from '../../../src/business/httpError';
import { AxiosError } from 'axios';
import { portal } from '../../assets/plugins/axios/axios';
import { UpdateEditorPluginConfigurationsRequest } from '../../models/requests/updateEditorPluginConfigurations.request';
import { GetBlockedCommunicationRequest } from '../../models/requests/getBlockedCommunication.request';

class MunicipalConfigurationService {
  public getEditorPlugins = async (municipalityCode: string) => {
    try {
      const { data } = await portal.get('?method=municipalConfiguration.getEditorPlugins', {
        params: { municipalityCode },
      });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public getBlockedCommunicationInstitutionProfilesAndGroups = async (payload: GetBlockedCommunicationRequest) => {
    try {
      const { data } = await portal.post(
        '?method=municipalConfiguration.getBlockedCommunicationInstitutionProfilesAndGroups',
        payload
      );
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public updateEditorPluginConfigurations = async (payload: UpdateEditorPluginConfigurationsRequest) => {
    try {
      await portal.post('?method=municipalConfiguration.updateEditorPluginConfigurations', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const municipalConfigurationService = new MunicipalConfigurationService();
